<template>
  <div v-if="!StatusLogin && !OTPpass">
    <div style="padding-top: 30px">
      <v-row align="center" justify="center" class="mt-10">
        <v-img src="@/assets/logo/BKLTH.png" max-width="300px"></v-img>
      </v-row>
    </div>
    <v-row align="center" justify="center" class="mt-4">
      <h1 class="text-center pt-10" style="color: #d5ab61; font-size: 30px">
        ข้อมูลผู้ใช้บริการ
      </h1>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="96vw">
        <v-card>
          <v-card-title class="white--text" style="background-color: #1d6fb8">
            <span style="font-size: 16px; font-weight: 700"
              >ข้อตกลงและเงื่อนไขในการซื้อลอตเตอรี่ออนไลน์ผ่าน
              บางกอกลอตเตอรี่.com</span
            >
            <!-- <span style="font-size: 16px; font-weight: 700"
              >และลักษณะที่สำคัญของบริการ</span
            >
            <br /> -->
            <!-- <span style="font-size: 16px; font-weight: 700"
              >www.DDLottery.com และ บางกอกลอตเตอรี่.com</span
            >
            <br />
            <span style="font-size: 16px; font-weight: 700">
              โดย บริษัท มังกร ดิจิตอล จำกัด
            </span> -->
            <!-- <span style="font-size:16px; font-weight:700">(BD00029D) ยี่ปั๊วรายใหญ่</span> -->
          </v-card-title>

          <v-card-text>
            <span>
              1.บางกอกลอตเตอรี่.com คือ
              แพลตฟอร์มช่องทางการจำหน่ายสลากกินแบ่งรัฐบาล
              โดยผู้จำหน่ายสลากจะเรียกเก็บค่าบริการในการค้นหาตัวเลขตามที่ลูกค้าต้องการ
            </span>
            <br />
            <span>
              2. ลูกค้าผู้ซื้อสลากกินแบ่งรัฐบาล หรือ ลอตเตอรี่
              ผ่านทางบางกอกลอตเตอรี่.comจะต้องเป็นผู้มีอายุ 20 ปีขึ้นไป
              และไม่ได้ทำการสั่งซื้อขณะอยู่ในสถานศึกษา
            </span>
            <br />
            <span>
              3. การซื้อสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่ในรูปแบบออนไลน์ผ่านทางบางกอกลอตเตอรี่.com
              เป็นการซื้อในรูปแบบการซื้อฝากร้านเท่านั้น
              หมายถึงเมื่อลูกค้าซื้อสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่กับผู้จำหน่ายสลากผ่านทางบางกอกลอตเตอรี่.comแล้ว
              ลูกค้ายินยอมและสมัครใจที่จะฝากต้นฉบับสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่
              ไว้กับบางกอกลอตเตอรี่.comให้เป็นผู้ดูแลรักษาและจัดเก็บอย่างเป็นระบบไว้แทน
              และสามารถติดต่อขอรับต้นฉบับได้หากถูกรางวัล
            </span>
            <br />
            <span>
              4. การอัพโหลด (Upload) หลักฐานการชำระเงิน หรือ สลิปโอนเงิน
              หากลูกค้าแนบสลิปผิดพลาด
              หรือเกิดข้อผิดพลาดประการใดเกี่ยวกับการอัปโหลดหลักฐานการชำระเงิน
              ให้ลูกค้ารีบติดต่อผู้จำหน่ายสลากทันทีเพื่อดำเนินการแก้ไขปัญหาที่เกิดขึ้น
              หรือแนบสลิปใหม่ หากกรณีไม่ได้รับการยืนยันคำสั่งซื้อ
              บางกอกลอตเตอรี่.comขอสงวนสิทธิในการไม่รับผิดชอบในข้อผิดพลาดดังกล่าวใด
              ๆ ทั้งสิ้น
            </span>
            <br />
            <span>
              5. หากลูกค้าอัพโหลดหลักฐานการชำระเงินที่ไม่ตรงกับข้อมูลการสั่งซื้อ
              (สลิปที่ไม่ถูกต้อง)
              ด้วยมีเจตนากระทำการอย่างหนึ่งอย่างใดอันเป็นการเชื่อได้ว่าเป็นการกระทำโดยเจตนาทุจริต
              หากบางกอกลอตเตอรี่.comสืบทราบ และ/หรือ พบเห็นการกระทำดังกล่าว
              บางกอกลอตเตอรี่.comสงวนสิทธิและถือว่าการสั่งซื้อนั้นเป็นโมฆะ
              บางกอกลอตเตอรี่.comจะดำเนินการยกเลิกคำสั่งซื้อและไม่มีการจัดส่งลอตเตอรี่ที่ถูกรางวัลให้แก่บุคคลนั้น
              พร้อมทั้งนำไอดี (ID) ผู้ใช้งานเข้าในระบบแบล็คลิสต์ (Blacklist)
              เพื่อไม่ให้ลูกค้าดำเนินการสั่งจองหรือสั่งซื้อใด ๆ
              ผ่านทางบางกอกลอตเตอรี่.comได้อีกต่อไป
              และบางกอกลอตเตอรี่.comขอสงวนสิทธิ์ในการดำเนินการแจ้งความร้องทุกข์ต่อพนักงานสอบสวนเพื่อดำเนินคดีต่อไป
            </span>
            <br />
            <span>
              6.
              รายการสั่งซื้อของลูกค้าจะดำเนินการสำเร็จต่อเมื่อผู้จำหน่ายสลากทำการตรวจสอบและอนุมัติรายการสั่งซื้อของลูกค้าแล้วเท่านั้น
              หากรายการสั่งซื้อสำเร็จ ลูกค้าจะได้รับข้อความ (SMS)
              แจ้งสถานะคำสั่งซื้อสำเร็จส่งไปยังหมายเลขโทรศัพท์มือถือที่ลูกค้าใช้ลงทะเบียนไว้จึงเป็นการเสร็จสิ้น
              คำสั่งซื้อสำเร็จ
              หากลูกค้าไม่ได้รับข้อความแจ้งคำสั่งซื้อไปยังหมายเลขโทรศัพท์ที่ลูกค้าใช้ลงทะเบียนไว้
              ขอให้ลูกค้ากรุณาติดต่อผู้จำหน่ายสลากที่ลูกค้าได้ติดต่อเพื่อซื้อลอตเตอรี่
              หรือ
              หากเกิดปัญหาภายหลังการชำระเงินแล้วไม่สามารถติดต่อผู้จำหน่ายสลากได้
              ลูกค้าสามารถติดต่อร้องเรียนมาที่สำนักงานใหญ่ได้ที่ บางกอกลอตเตอรี่.com/complain
            </span>
            <br />
            <span>
              7. หลังจากคำสั่งซื้อของลูกค้าสำเร็จแล้วนั้น
              ลูกค้าจะไม่สามารถทำการแก้ไข หรือ ทำการยกเลิกคำสั่งซื้อนั้นได้
            </span>
            <br />
            <!-- <span>
              8. บัญชีลูกค้าผู้ขอใช้บริการสามารถดูประวัติการสั่งซื้อ
              และรายการถูกรางวัล หรือรายการเคลื่อนไหวบัญชี ได้บน เวบไซต์
              บางกอกลอตเตอรี่.com และ บางกอกลอตเตอรี่.com
            </span>
            <br />
            <span>
              9. ถึงแม้บริษัทฯ
              จะเป็นผู้อำนวยความสะดวกดำเนินการขึ้นรางวัลโอนเงินรางวัลให้แก่ลูกค้า
              ในบางกรณี สำหรับบางรางวัลใหญ่ต่างๆ
              และโดยเฉพาะอย่างยิ่งรางวัลแจ๊คพ๊อต ทางเอเย่นต์ทางการในต่างประเทศ
              อาจต้องการให้ลูกค้าเป็นผู้เดินทางไปรับรางวัลโดยตรงด้วยตนเอง ณ
              ประเทศเจ้าของรางวัลลอตเตอรี่ (ลอตเตอรี่พาวเวอร์บอล
              ที่ประเทศสหรัฐอเมริกา, เมกะมิลเลี่ยนส์ สหรัฐอเมริกา, ยูโรแจ๊คพ๊อต
              (สหภาพยุโรป), พาวเวอร์บอล ประเทศออสเตรเลีย, มินิลอตโต้
              ประเทศญี่ปุ่น, ลอตเตอรี่ลาวพัฒนา ที่ สปป.ลาว) โดย ddlottery
              จะเป็นผู้แนะนำและช่วยอำนวยความสะดวกให้แก่ลูกค้าเพื่อการติดต่อไปรับรางวัลโดยตรงกับเอเย่นต์ทางการ
              โดยค่าใช้จ่ายจะเป็นความรับผิดชอบของลูกค้าทั้งหมด
            </span>
            <br />
            <span>
              10. หากมีการโต้แย้งความผิดพลาด ในกรณีเลขสลากไม่ตรงกับที่สั่งซื้อ
              หรือ มีการปฏิเสธการสั่งซื้อจากต่างประเทศทุกกรณี
              ก่อนเวลาประกาศผลรางวัล การสั่งซื้อนั้นจะถูกยกเลิกโดยอัตโนมัติ
              และลูกค้าสามารถแจ้งขอรับเงินโอนคืนตามจำนวนเงินที่ชำระมา 100%
              หรือลูกค้าสามารถกดสั่งซื้อใหม่ได้อีกครั้งโดยไม่ต้องชำระเพิ่มอีกครั้ง
              สำหรับการสั่งซื้อจำนวนสลากเท่าเดิมในงวดถัดไป
            </span>
            <br />
            <span>
              11. ทางบริษัทฯ ขอสงวนสิทธิ์ ไม่แสดงที่มาของรัฐหรือเมือง
              เลขประจำตัวแทนที่ออกสลาก เนื่องจากจะทำให้ตัวแทนถูกตัดสิทธิ์การขาย
              กรณีที่นำมาให้ผู้อื่นขายต่อ เนื่องจากตัวแทนไม่ขายตรงด้วยตนเอง
              (เหมือนกรณีตัวแทนจำหน่ายสลากลอตเตอรี่ในประเทศไทย)
              หากลูกค้าได้กดสั่งซื้อแล้ว และก่อนการออกรางวัลสลากนั้น
              ได้แจ้งไม่ตกลงตามเงื่อนไขข้อ 11 นี้ ทางบริษัทฯ
              ขอแจ้งคืนเงินเต็มจำนวนลูกค้าใน 24 ชม.
              และให้ถือเป็นการยกเลิกออเดอร์คำสั่งซื้อและเป็นอันสิ้นสุด
            </span>
            <br />
            <span>
              12. ลูกค้าสามารถสแกนบาร์โค้ดเพื่อตรวจสอบสลากได้
              โดยผ่านระบบการตรวจสอบบาร์โค้ดจากประเทศของลอตเตอรี่นั้นๆ ทั้งนี้
              การสแกนสลาก อาจจะทำให้บาร์โค้ด
              และขนาดของใบสลากที่สแกนผิดเพี้ยนจากขนาดจริง
              อาจทำให้สแกนไม่ติดได้ในบางรูป โดยบริษัทฯ
              ขอสงวนสิทธิ์ไม่สแกนเพิ่มเติมให้อีกครั้ง หากลูกค้าได้กดสั่งซื้อแล้ว
              และก่อนการออกรางวัลสลากนั้น ได้แจ้งไม่ตกลงตามเงื่อนไขข้อ 12 นี้
              ทางบริษัทฯ ขอแจ้งคืนเงินเต็มจำนวนลูกค้าใน 24 ชม.
              และให้ถือเป็นการยกเลิกออเดอร์คำสั่งซื้อและเป็นอันสิ้นสุด
            </span>
            <br />
            <span>
              13. ผู้ขอใช้บริการจะทำการตรวจสอบเอกสาร การยืนยัน ข้อมูล
              และเอกสารต่าง ๆ ทั้งหมดที่ส่งถึงผู้ขอใช้บริการผ่านทางแพลตฟอร์ม
              บางกอกลอตเตอรี่.com และ บางกอกลอตเตอรี่.com
              (โดยเฉพาะอย่างยิ่งเอกสารรูปภาพสแกนลอตเตอรี่ที่ส่งผ่านบัญชีลูกค้า
              ddlottery.net) อย่างละเอียดรอบคอบทันทีที่ได้รับเอกสารดังกล่าว
              และจะดำเนินการแจ้งให้ทราบถึงการโต้แย้งความถูกต้องใด ๆ
              ในเอกสารดังกล่าวเป็นลายลักษณ์อักษรก่อนวันและเวลาประกาศผลรางวัลนั้นๆ
              นับจากที่ได้รับข้อมูลและเอกสารรูปภาพสแกนลอตเตอรี่ใบจริงดังกล่าวผ่านทางแพลตฟอร์ม
              ddlottery
              โดยในกรณีที่ไม่มีการโต้แย้งดังกล่าวจากผู้ขอใช้บริการภายในกำหนดเวลาข้างต้นให้ถือว่าการยืนยัน
              ข้อมูล และ/หรือเอกสารต่าง ๆ
              ข้างต้นถูกต้องและมีผลผูกพันผู้ขอใช้บริการทุกประการ
            </span>
            <br />
            <span>
              14. หากผู้ใช้บริการสั่งซื้อในช่วงเวลา 2-3 ชม.
              ก่อนการประกาศผลรางวัล (ช่วงระหว่างงวด)
              การสั่งซื้อของลูกค้าอาจจะถูกตัดเป็นงวดปัจจุบัน
              หรืออาจจะส่งไปเป็นคำสั่งซื้อของงวดถัดไปอัตโนมัติทันที
              กรุณาตรวจสอบวันประกาศรางวัลบนสำเนาสแกนสลากฯ
              ของท่านในประวัติการสั่งซื้อ
            </span> -->
          </v-card-text>
          <!-- <v-checkbox
            v-model="checkPrivacy"
            label="ข้าพเจ้ายอมรับเงื่อนไขและข้อตกลงทั้งหมด"
          ></v-checkbox> -->
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false"> ถอยกลับ </v-btn>
            <v-btn
              color="#d5ab61"
              @click="(dialog = false), (checkPrivacy = true)"
            >
              ฉันยอมรับ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-row align="center" justify="center" no-gutters>
      <v-card
        class="mt-4 pa-6 text-center ml-4"
        style="background-color: #1d6fb8"
        width="600px"
      >
        <h1 style="color: white; font-weight: 800">กรอกเบอร์มือถือ</h1>
        <span style="color: white"
          >ระบบจะส่งรหัส OTP ให้ทาง SMS เพื่อยืนยัน</span
        >
        <v-row class="mt-2" no-gutters justify="center">
          <v-col cols="12">
            <v-row justify="center">
              <!-- <span
                class="my-5"
                style="
                  text-decoration: underline;
                  color: white;
                  font-size: 14px;
                "
                @click="dialogPrivacy()"
              >
                ข้อกำหนดและเงื่อนไขบริการ</span
              > -->
              <v-col cols="12" class="mt-4" v-if="false">
                <v-row justify="center">
                  <v-checkbox
                    v-model="checkPrivacy"
                    label="ฉันยอมรับ "
                    style="color: white"
                    dark
                    class="pr-1"
                    @click="dialogPrivacy()"
                  >
                  </v-checkbox>
                  <span
                    class="my-5"
                    style="text-decoration: underline; font-size: 16px"
                    @click="dialogPrivacy()"
                  >
                    ข้อกำหนดและเงื่อนไขบริการ</span
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="8" md="8" sm="8" @click="checkPrivacyNa()"> -->
            <v-col cols="8" md="8" sm="8" >
            <v-text-field
              v-model="telNumber"
              v-mask="'###-###-####'"
              placeholder="กรอกเบอร์มือถือ 10 หลัก"
              solo
              background-color="white"
              dense
              @keyup.enter="OTP()"
            ></v-text-field>
          </v-col>
          <v-col cols="4" md="2" sm="2">
            <v-btn
              style="
                background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
              "
              @click="OTP()"
              >ขอรหัส</v-btn
            >
          </v-col>
        </v-row>
        <!-- <span style="color: white">
          มีเพียงสมาชิกเก่าเท่านั้นที่สามารถเข้าสู่ระบบผ่านเบอร์มือถือได้
          หากเป็นสมาชิกใหม่โปรดสมัครผ่านช่องทางไลน์</span
        >
        <h3 style="color: white; font-weight: 800">หรือ</h3>
        <v-btn color="#02c756" style="color: white" @click="lineLogin()"
          >สมัครสมาชิกด้วยไลน์</v-btn
        > -->
      </v-card>
    </v-row>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
  <div v-else-if="OTPpass">
    <div style="padding-top: 30px">
      <v-row align="center" justify="center" class="mt-10">
        <v-img src="@/assets/logo/BKLTH.png" max-width="300px"></v-img>
      </v-row>
    </div>
    <v-row align="center" justify="center" no-gutters>
      <br />
      <br />
      <br />
      <br />
      <v-card
        class="mt-6 ma-4 pa-6 text-center"
        style="background-color: #156fc4"
        width="600px"
      >
        <h1 style="color: white; font-weight: 800">ยืนยันรหัส OTP</h1>
        <span style="color: white"
          >กรอกรหัส OTP ที่ได้รับจากโทรศัพท์มือถือ</span
        >
        <v-row class="mt-2" no-gutters justify="center">
          <v-col cols="8" md="8" sm="8">
            <v-text-field
              v-model="otp_code"
              v-mask="'######'"
              solo
              background-color="white"
              dense
              @keyup.enter="verifyOTP()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="mb-2">
            <v-btn
              style="
                background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
              "
              @click="verifyOTP()"
              >ยืนยันหมายเลข OTP</v-btn
            >
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-btn @click="OTP()">ขอรหัส OTP ใหม่อีกครั้ง</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </div>
  <div v-else class="mb-10 mt-10 pt-10 pb-10">
    <v-form ref="form1" :lazy-validation="lazy" v-model="checkValidate">
      <v-row align="center" justify="center" no-gutters>
        <v-card
          class="pa-6 mx-2 text-center"
          style="background-color: #1d6fb8"
          width="600px"
        >
          <v-row style="font-size: 20px" class="my-2" dense>
            <v-col cols="12">
              <h2 style="font-weight: 800">ข้อมูลส่วนตัวลูกค้า</h2>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="mb-4"
                large
                block
                :hidden="disabledEdit === true ? false : true"
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                @click="gotohome()"
                >เลือกซื้อลอตเตอรี่</v-btn
              >
              <v-btn
                class="mb-4"
                large
                block
                :hidden="disabledEdit === true ? false : true"
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                @click="edit()"
                >แก้ไข</v-btn
              >
              <v-btn
                class="mb-4"
                large
                block
                type="reset"
                :hidden="disabledEdit === false ? false : true"
                @click="cancel()"
                >ยกเลิก</v-btn
              >
              <v-btn
                class="mb-4"
                large
                block
                :hidden="disabledEdit === false ? false : true"
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                @click="submit()"
                >บันทึก</v-btn
              >
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>ชื่อ*</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <!-- :disabled="disabledEdit" -->
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="ชื่อ"
                v-model="firstName"
                :disabled="isDisableFirst"
                :rules="Rules.firstName"
              ></v-text-field
            ></v-col>
            <!-- :disabled="firstName != ''" -->
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>นามสกุล*</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="นามสกุล"
                v-model="lastName"
                :disabled="isDisableFirst"
                :rules="Rules.lastName"
              ></v-text-field
            ></v-col>
            <!-- :disabled="lastName != ''" -->
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>อีเมล</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details="auto"
                label="หากไม่มีให้เว้นว่างไว้"
                v-model="email"
                :disabled="disabledEdit"
                hint="หากไม่มีให้เว้นว่างไว้"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>หมายเลขโทรศัพท์มือถือ</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="หมายเลขโทรศัพท์มือถือ"
                v-model="tel"
                :disabled="true"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>หมายเลขโทรศัพท์มือถือ (ฉุกเฉิน)</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="หมายเลขโทรศัพท์มือถือ (ฉุกเฉิน)"
                v-model="telTwo"
                :disabled="disabledEdit"
              ></v-text-field
            ></v-col>
            <!-- <v-col cols="12" md="12" sm="12" class="text-left">
              <label>Line</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="Line"
                v-model="line"
                :disabled="disabledEdit"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>Facebook</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="Facebook"
                v-model="facebook"
                :disabled="disabledEdit"
              ></v-text-field
            ></v-col> -->
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>ยินดีรับการแจ้งเตือนและรับข่าวสารโปรโมชัน</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-checkbox
                label="SMS"
                value="SMS"
                color="#fbe599"
                :disabled="disabledEdit"
                row
              >
              </v-checkbox>
              <v-checkbox
                label="Email"
                value="Email"
                color="#fbe599"
                :disabled="disabledEdit"
                row
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" md="6" sm="12">
              <h4 style="color: red;">
                ***หากต้องการแก้ใขบัญชีการรับเงินรางวัล แก้ใขได้แค่ชื่อธนาคารและหมายเลขบัญชีเท่านั้น
                ไม่สามารถแก้ใขเจ้าของชื่อบัญชีหลังจากเพิ่มในครั้งแรกแล้ว 
              </h4>
            </v-col> -->
            <v-col cols="12">
              <h2 style="font-weight: 800" class="mt-4">บัญชีรับเงินรางวัล</h2>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>ชื่อธนาคาร</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-select
                solo
                background-color="white"
                dense
                hide-details
                :items="[
                  { name: 'ธนาคารกรุงเทพฯ', value: 'ธนาคารกรุงเทพฯ' },
                  { name: 'ธนาคารไทยพาณิชย์ฯ', value: 'ธนาคารไทยพาณิชย์ฯ' },
                  { name: 'ธนาคารกสิกรไทย', value: 'ธนาคารกสิกรไทย' },
                  { name: 'ธนาคารกรุงศรีอยุธยา', value: 'ธนาคารกรุงศรีอยุธยา' },
                  { name: 'ธนาคารธนชาต', value: 'ธนาคารธนชาต' },
                  { name: 'ธนาคารกรุงไทย', value: 'ธนาคารกรุงไทย' },
                  { name: 'ธนาคารทิสโก้', value: 'ธนาคารทิสโก้' },
                  { name: 'ธนาคารออมสิน', value: 'ธนาคารออมสิน' },
                  {
                    name: 'ธนาคารอาคารสงเคราะห์',
                    value: 'ธนาคารอาคารสงเคราะห์',
                  },
                  {
                    name: 'ธนาคารอิสลามแห่งประเทศไทย',
                    value: 'ธนาคารอิสลามแห่งประเทศไทย',
                  },
                  {
                    name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
                    value: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
                  },
                  { name: 'ธนาคารทหารไทยธนชาต', value: 'ธนาคารทหารไทยธนชาต' },
                ]"
                item-text="name"
                label="ชื่อธนาคาร"
                v-model="bankName"
                :disabled="disabledEdit"
                :rules="Rules.required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>ชื่อ - นามสกุล</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                :value="`${firstName} ${lastName}`"
                disabled
                :rules="Rules.firstName"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="12" sm="12" class="text-left">
              <label>เลขบัญชี</label>
              <label style="color: red">*</label>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                solo
                background-color="white"
                dense
                hide-details
                label="หมายเลขบัญชี"
                v-model="bankNo"
                :disabled="disabledEdit"
                :rules="Rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card>
                <span style="color: red">
                  ***หากต้องการแก้ใขบัญชีการรับเงินรางวัล แก้ใขได้แค่
                  ชื่อธนาคารและหมายเลขบัญชีเท่านั้น
                  ไม่สามารถแก้ใขเจ้าของชื่อบัญชีหลังจากเพิ่มในครั้งแรกแล้ว
                </span>
              </v-card>
            </v-col>
          </v-row>
          <v-btn
            class="mb-4"
            large
            block
            :hidden="disabledEdit === true ? false : true"
            style="
              background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
            "
            @click="edit()"
            >แก้ไข</v-btn
          >
          <v-btn
            class="mb-4"
            large
            block
            type="reset"
            :hidden="disabledEdit === false ? false : true"
            @click="cancel()"
            >ยกเลิก</v-btn
          >
          <v-btn
            class="mb-4"
            large
            block
            :hidden="disabledEdit === false ? false : true"
            style="
              background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
            "
            @click="submit()"
            >บันทึก</v-btn
          >
        </v-card>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
export default {
  data() {
    return {
      check: false,
      disabledEdit: true,
      lazy: false,
      checkValidate: true,
      dialog: false,
      checkPrivacy: false,
      telNumber: "",
      StatusLogin: false,
      completeRegister: false,
      OTPpass: false,
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      tel: "",
      line: "",
      facebook: "",
      telTwo: "",
      warn: "sms",
      bankNo: "",
      bankName: "",
      // api_key: "aae12e35fbf90940f738bc5c4cf55e39",
      // secret_key: "cPPh2HNrdhAR8QM2",
      // project_key: "4K1k8nvj2h",
      api_key: "d551e09b93553c9970e9634a5046cd5a",
      secret_key: "i0rdbEDaUdqyBo3r",
      // project_key: "4K1k8nvj2h",
      project_key: "1b835546c3",
      token: "",
      otp_code: "",
      Rules: {
        firstName: [(v) => !!v || "กรุณากรอกชื่อ"],
        lastName: [(v) => !!v || "กรุณากรอกนามสกุล"],
        mobile: [(v) => !!v || "กรุณากรอกเบอร์โทรศัพท์"],
        lineid: [(v) => !!v || "กรุณากรอกไลน์ไอดี"],
        gender: [(v) => !!v || "กรุณาเลือกเพศ"],
        job: [(v) => !!v || "กรุณาเลือกอาชีพ"],
        age: [(v) => !!v || "กรุณากรอกอายุ"],
        otherJob: [(v) => !!v || "กรุณากรอกอาชีพ"],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      isDisableFirst: true,
    };
  },
  methods: {
    async checkPrivacyNa() {
      if (this.checkPrivacy == false) {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณายอมรับข้อกำหนดและเงื่อนไขบริการ",
        });

      //   await this.$swal.fire({
      //   icon: "success",
      //   html: "พบกับการเปิดตัว บางกอกลอตเตอรี่.com แพลตฟอร์มสลากดิจิทัลเอกชน ราคา 80 บาท เร็วๆ นี้ <br/>แอดไลน์เพิ่มเพื่อนไว้รอซื้อได้เลย คลิกที่นี่<br/><a href='https://bit.ly/3yRVDzc'>https://bit.ly/3yRVDzc</a>",
      //   showConfirmButton: true,
      //   // timer: 1500,
      // });
      }
    },
    async OTP() {

      this.login();
      // if (this.checkPrivacy == false) {
      //   await this.$swal.fire({
      //     showConfirmButton: false,
      //     timer: 1500,
      //     timerProgressBar: true,
      //     icon: "error",
      //     text: "กรุณายอมรับข้อกำหนดและเงื่อนไขบริการ",
      //   });
      // } else {
      //   // let formData = new FormData();
      //   const auth = {
      //     headers: {
      //       "Content-Type": "application/json",
      //       api_key: this.api_key,
      //       secret_key: this.secret_key,
      //     },
      //   };
      //   const params = {
      //     project_key: this.project_key,
      //     phone: this.telNumber,
      //   };
      //   const response = await this.axios.post(
      //     `https://portal-otp.smsmkt.com/api/otp-send`,
      //     params,
      //     auth
      //   );
      //   console.log("response", response);
      //   this.token = response.data.result.token;
      //   console.log("token", this.token);
      //   if (response.data.code === "000") {
      //     this.OTPpass = true;
      //   } else if (this.telNumber == "") {
      //     await this.$swal.fire({
      //       showConfirmButton: false,
      //       timer: 1000,
      //       timerProgressBar: true,
      //       icon: "error",
      //       text: "กรุณากรอกหมายเลขโทรศัพท์",
      //     });
      //   } else {
      //     await this.$swal.fire({
      //       showConfirmButton: false,
      //       timer: 1500,
      //       timerProgressBar: true,
      //       icon: "error",
      //       text: "ระบบ OTPขัดข้อง",
      //     });
      //   }
      // }
    },
    async verifyOTP() {
      const auth = {
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
          // "Access-Control-Allow-Origin": "*"
        },
      };
      const params = {
        token: this.token,
        otp_code: this.otp_code,
      };
      console.log("param", params);
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-validate`,
        params,
        auth
      );
      console.log("verify otp success", response);
      // if (response.data.code === "000") {
      // if (response.data.code === "000") {
      // if (response.data.code === "000") {
      // if (response.data.code === "000") {
      if (response.data.result.status === true) {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });
        this.StatusLogin = true;
        this.login();
        // this.$router.push("/");
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "รหัส OTP ของท่านผิด กรุณากรอกใหม่อีกครั้ง",
        });
      }
    },
    async login() {
      const data = {
        socialId: "",
        telNumber: this.telNumber.replaceAll("-", ""),
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/login`,
        data
      );
      console.log("response", response);
      if (response.data.response_status === "SUCCESS") {
        const data = {
          token: response.data.data.token,
          user: response.data.data.user,
        };
        console.log(data);
        localStorage.setItem("DDLotUser", encode(data));
        window.location.reload();
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "ไม่มีข้อมูลผู้ใช้งาน โปรดติดต่อเจ้าหน้าที่",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    lineLogin() {
      location.replace(
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1655949122&redirect_uri=https://readylottery.com/redirect&state=12345abcde&scope=profile%20openid&nonce=09876xyz"
      );
    },
    async submit() {
      if (this.$refs.form1.validate(true)) {
        var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          telNumber: this.tel,
          email: this.email,
          bankName: this.bankName,
          bankNo: this.bankNo,
          bankNo: this.bankNo,
          bankName: this.bankName,
        };
        console.log("ส่งไป", data, auth);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/users/` + this.id,
          data,
          auth
        );
        this.$swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
          disabledEdit: true,
        });
        console.log("update complete", response);
        user.user = response.data.data;
        console.log("ข้อมูลก่อนเข้ารหัส", user);
        localStorage.removeItem("DDLotUser");
        localStorage.setItem("DDLotUser", encode(user));
        this.$router.push("/");
        this.disabledEdit = true;
      } else {
        this.$swal.fire({
          icon: "error",
          title: "กรุณากรอกข้อมูลให้ครบ",
          showConfirmButton: false,
          timer: 1500,
          disabledEdit: false,
        });
      }
      // async getUser() {
      //   var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      //   const auth = {
      //     headers: { Authorization: `Bearer ${user.token}` },
      //   };
      //   const response = await this.axios.get(
      //     `${process.env.VUE_APP_API}/users/` + user.user.id,
      //     auth
      //   );
      //   console.log("getuser", response.data.data);
      //   localStorage.setItem("DDLotUser", encode(response.data.data));
      // },
    },
    dialogPrivacy() {
      this.dialog = true;
    },
    gotohome() {
      this.$router.push("/");
    },
    edit() {
      this.disabledEdit = false;
    },
    async cancel() {
      // location.reload();
      if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
        this.StatusLogin = true;
        var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
        console.log("userdata", user);
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/users/` + user.user.id,
          auth
        );
        console.log("response", response);
        this.id = response.data.data.id;
        this.firstName = response.data.data.firstName;
        this.lastName = response.data.data.lastName;
        this.tel = response.data.data.telNumber;
        this.email = response.data.data.email;
        this.bankName = response.data.data.bankName
          ? response.data.data.bankName
          : "";
        this.bankNo = response.data.data.bankNo
          ? response.data.data.bankNo
          : "";
        if (
          response.data.data.firstName &&
          response.data.data.firstName !== ""
        ) {
          this.completeRegister = true;
          // this.$router.push("/");
        } else {
          this.isDisableFirst = false;
        }
      }
      this.disabledEdit = true;
    },
  },
  async created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
      this.StatusLogin = true;
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      console.log("userdata", user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/` + user.user.id,
        auth
      );
      console.log("response", response);
      this.id = response.data.data.id;
      this.firstName = response.data.data.firstName;
      this.lastName = response.data.data.lastName;
      this.tel = response.data.data.telNumber;
      this.email = response.data.data.email;
      this.bankName = response.data.data.bankName
        ? response.data.data.bankName
        : "";
      this.bankNo = response.data.data.bankNo ? response.data.data.bankNo : "";
      if (response.data.data.firstName && response.data.data.firstName !== "") {
        this.completeRegister = true;
        this.isDisableFirst = true;
        // this.$router.push("/");
      } else {
        this.isDisableFirst = false;
      }
    }
  },
};
</script>

<style scoped>
.white--text /deep/ label {
  color: white;
}
</style>
